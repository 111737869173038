import {
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { OperationBar } from "./AdminOperationBar";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { ToastContext } from "../../context/ToastContext";
import {
  createTradeUser,
  deleteTradeUser,
  getAllTradeUsers,
  updateTradeUser,
} from "../../api_client/admin_client";
import { ConfirmDialog } from "../ConfirmDialog";
import { MdDelete, MdEdit } from "react-icons/md";

const TradeUserCard = ({ user, onDelete, onEdit, currentUserId }) => {
  return (
    <Card
      direction={"column"}
      overflow="hidden"
      variant="outline"
      w={"300px"}
      // p={2}
      m={2}
    >
      <Box
        position="relative"
        justify={"center"}
        align={"center"}
        // bg="gray.200"
      >
        <Image
          alignSelf={"center"}
          src={"/img/profile.jpg"}
          w={"300px"}
          h={"auto"}
          borderWidth={1}
          color={"gray.200"}
          borderColor={"gray.500"}
          borderStyle={"solid"}
          // filter="grayscale(50%)"
          objectFit="cover"
          // mixBlendMode="multiply"
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          bg="gray.200"
          opacity="0.5"
        />
      </Box>
      <Stack>
        <CardBody>
          <Heading size="md">{user.USERNAME}</Heading>
          <Flex w={"full"} align={"center"}>
            <VStack alignItems={"left"} textAlign={"left"} justify={"left"}>
              <Flex p={0} justify={"flex-start"} align={"center"}>
                <Text>Activated: {user.ACTIVATED ? "Y" : "N"}</Text>
              </Flex>
              <Text p={0}></Text>
            </VStack>
            <Spacer />
            <Flex px={2}>
              <IconButton
                px={2}
                mx={1}
                icon={<MdEdit />}
                fontSize={"1.2em"}
                colorScheme="red"
                variant={"outline"}
                onClick={() => onEdit(user)}
                aria-label="Edit"
              />
              <IconButton
                px={2}
                mx={1}
                fontSize={"1.2em"}
                icon={<MdDelete />}
                colorScheme={"red"}
                variant={"outline"}
                onClick={() => onDelete(user)}
                aria-label="Delete"
              />
            </Flex>
          </Flex>
        </CardBody>
      </Stack>
    </Card>
  );
};
const TradeUserTable = ({
  triggerReload,
  setTriggerReload,
  onEdit,
  searchStr,
}) => {
  const { currentUser, updateCurrentUser } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const [usersInPage, setUsersInPage] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure(); //for delete dialog
  useEffect(() => {
    const loadForPage = async () => {
      const rsp = await getAllTradeUsers(currentUser);
      if (rsp.success) {
        setUsersInPage(rsp.results);
      } else {
        showToast(rsp.message, "Error", "error");
        if (rsp.status === 401) {
          updateCurrentUser(null);
          navigate("/trade_login");
        }
      }
    };
    loadForPage();
  }, [triggerReload, showToast, currentUser, navigate, updateCurrentUser]);
  useEffect(() => {
    const f = usersInPage.filter((c) =>
      c.USERNAME.toUpperCase().includes(searchStr.toUpperCase())
    );
    setFiltered(f);
  }, [usersInPage, searchStr]);
  const handleDelete = async () => {
    const rsp = await deleteTradeUser(currentItem.ID, currentUser);
    if (!rsp.success) {
      showToast(rsp.message, "Login Required", "error");
      if (rsp.status === 401) {
        updateCurrentUser(null);
        navigate("/trade_login");
      }
    }
    setTriggerReload((p) => !p);
    onClose();
  };
  const onDeleteMe = (c) => {
    setCurrentItem(c);
    onOpen();
  };
  return (
    <Flex wrap={"wrap"} justify="space-even">
      {filtered || filtered.length === 0 ? (
        filtered.map((c) => (
          <Box position="relative">
            <TradeUserCard
              user={c}
              key={c.ID}
              onDelete={onDeleteMe}
              onEdit={onEdit}
              currentUserId={currentUser.ID}
            />
            <Checkbox
              colorScheme="red"
              position="absolute"
              top="2"
              left="2"
              bg="rgba(255, 255, 255, 0.7)"
              borderRadius="md"
              p="2"
            />
          </Box>
        ))
      ) : (
        <Box>
          <Text color={"gray.800"} fontSize={"md"} align={"center"}>
            Empty
          </Text>
        </Box>
      )}
      <ConfirmDialog
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        item={currentItem}
        info={currentItem ? currentItem.USERNAME : ""}
        yesAction={handleDelete}
      />
    </Flex>
  );
};
const NewTradeUserModal = ({ isOpen, onOpen, onClose, onRefresh, user }) => {
  const navigate = useNavigate();
  const { currentUser, updateCurrentUser } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isActivated, setIsActivated] = useState(true);
  const [errors, setErrors] = useState({
    username: false,
    password: false,
  });

  useEffect(() => {
    if (user) {
      setUsername(user.USERNAME);
      setPassword("");
      setIsActivated(user.ACTIVATED > 0);
    } else {
      setUsername("");
      setPassword("");
      setIsActivated(true);
    }
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const check = {
      ...errors,
      username: !username || username === "",
    };
    const anyError = Object.values(check).reduce((r, c) => r || c, false);
    if (anyError) {
      setErrors(check);
      return;
    }
    const formData = new FormData();
    if (user) {
      formData.append("id", user.ID);
    }
    formData.append("username", username);
    formData.append("password", password);
    formData.append("activated", isActivated);
    if (user) {
      const rsp = await updateTradeUser(user.ID, formData, currentUser);
      if (!rsp.success) {
        showToast(rsp.message, "Login Required", "error");
        if (rsp.status === 401) {
          updateCurrentUser(null);
          navigate("/trade_login");
        }
      }
    } else {
      const rsp = await createTradeUser(formData, currentUser);
      console.debug("create trade user: ", rsp);
      if (!rsp.success) {
        showToast(rsp.message, "Login Required", "error");
        if (rsp.status === 401) {
          updateCurrentUser(null);
          navigate("/trade_login");
        }
      }
    }
    onRefresh();
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={{ base: "80%", sm: "full" }}>
        <form onSubmit={handleSubmit}>
          <ModalHeader>{user ? "Edit" : "Create"} Trade User</ModalHeader>
          <ModalCloseButton colorScheme="red" />
          <ModalBody>
            <Box>
              <FormControl isInvalid={errors.username} pb={2}>
                <FormLabel>User Name</FormLabel>
                <Input
                  type="text"
                  name="user_name"
                  placeholder="Username"
                  value={username}
                  onChange={(event) => {
                    if (event.target.value !== "") {
                      setErrors((prev) => ({ ...prev, name: false }));
                    }
                    setUsername(event.target.value);
                  }}
                />
                {errors.username ? (
                  <FormErrorMessage>Color name is required.</FormErrorMessage>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </FormControl>
              <HStack>
                <FormControl>
                  <FormLabel>Is Activated</FormLabel>
                  <Checkbox
                    isChecked={isActivated}
                    onChange={(e) => setIsActivated(e.target.checked)}
                  />
                </FormControl>
              </HStack>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost" colorScheme="red" type="submit">
              {user ? "Update" : "Create"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
export const AdminTradeUserPanel = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [triggerReload, setTriggerReload] = useState(true);
  const [currentItem, setCurrentItem] = useState(null);
  const [searchStr, setSearchStr] = useState("");
  const onRefresh = () => {
    setTriggerReload((p) => !p);
  };
  const onEdit = (item) => {
    console.debug("on edit user: ", item);
    setCurrentItem(item);
    onOpen();
  };
  const onNewClear = () => {
    setCurrentItem(null);
    onOpen();
  };
  return (
    <Box w={"full"}>
      <OperationBar
        onNew={onNewClear}
        onRefresh={onRefresh}
        searchStr={searchStr}
        setSearchStr={setSearchStr}
      />
      <TradeUserTable
        triggerReload={triggerReload}
        setTriggerReload={setTriggerReload}
        searchStr={searchStr}
        onEdit={onEdit}
      />
      <NewTradeUserModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onRefresh={onRefresh}
        user={currentItem}
      />
    </Box>
  );
};
