import React, { useState,useEffect, useRef} from "react";
import {
  Box,
  Grid,
  GridItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
//   List,
//   ListItem,
  Spinner,
  Text,
} from "@chakra-ui/react";
// import { debounce } from "lodash";
import { searchProduct } from "../api_client/fetch_client";
import { SingleProduct } from "./ProductsSlider";

const SearchModal = ({ isOpen, onClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [isOpen]);

  const fetchResults = async (query) => {
    if (!query || query.length <= 3) return;
    setIsLoading(true);
    setResults([]);
    try {
        
      const response = await searchProduct(query)
      console.debug(response)
      if (response.success) {
        setResults(response.result)
      } else {
        console.debug("no result found")
        setResults([])
      }

    } catch (error) {
      console.error("Error fetching results", error);
    }
    setIsLoading(false);
    // inputRef.current?.focus();
    setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
  };

//   const debouncedSearch = useCallback(debounce(fetchResults, 500), []);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value.length > 3) {
    //   debouncedSearch(event.target.value);
      fetchResults(event.target.value);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      
      <ModalOverlay onClick={onClose} />
      <ModalContent minW="60vw" maxH="80vh" border="2px solid gray" mt="20vh" mx="auto">
        <ModalHeader textAlign="center" fontWeight="bold">
          Search Products
        </ModalHeader>
        <ModalCloseButton bg="red.500" />
        <ModalBody bg="gray.100">
          <Input
            ref={inputRef}
            placeholder="Type to search..."
            value={searchTerm}
            onChange={handleInputChange}
            isDisabled={isLoading}
          />
          {isLoading && <Spinner mt={4} color="red.500" />}
          {!isLoading && <Box>
            <Text size="sm">
                {results.length>0? `${results.length} products found`:"No product found"}
            </Text>
            </Box>}
          <Grid
            templateColumns={{
                base: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
            }}
            gap={12}
            >
            {results.map((p, idx) => (
                <GridItem key={idx} maxW={"200px"} width="100%">
                <SingleProduct product={p} />
                </GridItem>
            ))}
          </Grid>
          {/* <List mt={4} maxH="50vh" overflowY="auto">
            {results.map((result, index) => (
              <ListItem key={index}>{result.NAME}</ListItem>
            ))}
          </List> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SearchModal;
