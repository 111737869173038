import {
  IconButton,
  Avatar,
  Box,
  Button,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useDisclosure,
  Menu,
  MenuButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import {
  // FiTrendingUp,
  FiCompass,
  // FiStar,
  // FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from "react-icons/fi";
import {
  NavLink,
  Outlet,
  Link as RouteLink,
  // redirect,
  useNavigate,
} from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { AppContext } from "../../context/AppContext";
import { ToastContext } from "../../context/ToastContext";
import { changePasswordForTradeUser } from "../../api_client/admin_client";

const LinkItems = [
  // { name: "Home", icon: FiHome, path: "/trade" },
  { name: "Price", icon: FiCompass, path: "/trade/price" },
  // { name: "Category", icon: FiCompass, path: "/trade/category" },
  // { name: "SubCategory", icon: FiStar, path: "/trade/subcategory" },
  // { name: "Product", icon: FiSettings, path: "/trade/product" },
  // { name: "Carousel", icon: FiSettings, path: "/trade/carousel" },
];

const SidebarContent = ({ onClose, ...rest }) => {
  const { priceFiles, setCurrentPriceFileKey } = useContext(AppContext);
  return (
    <Flex
      direction={"column"}
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Box p={2}>
          <a href="/">
            <img src="/img/logo/logo33.png" alt="logo" />
          </a>
        </Box>
        {/*<Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">*/}
        {/*  {" "}*/}
        {/*  [Trade User]*/}
        {/*</Text>*/}
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Flex direction={"column"}>
        {LinkItems.map((link) => (
          <NavItem key={link.name} icon={link.icon} path={link.path}>
            {link.name}
          </NavItem>
        ))}
      </Flex>
      <Flex flex={1} h={"70%"}>
        <Flex flex={1} h={"full"} direction="column" gap={2} overflowY="auto">
          {priceFiles.map((p, idx) => (
            <Box
              key={idx}
              onClick={() => setCurrentPriceFileKey(p)}
              // w="280px" // Adjust the width as needed
              // h="100px" // Adjust the height as needed
              p={2}
              mx={4}
              bg="gray.500"
              display="flex"
              alignItems="start"
              justifyContent="start"
              color="white"
              fontSize="md"
              borderRadius="lg"
              cursor="pointer"
              _hover={{
                bg: "gray.300", // Change background color on hover
                color: "black", // Change text color on hover
              }}
            >
              {p.name}
            </Box>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

const NavItem = ({ icon, children, path, ...rest }) => {
  const activeStyle = {
    bg: "red.400",
    color: "white",
  };
  // console.debug("nav item: ", path, ", rest:", rest);
  return (
    <NavLink to={path}>
      {({ isActive }) => (
        <Box
          as={RouteLink}
          to={path}
          p={1}
          style={{ textDecoration: "none" }}
          _focus={{ boxShadow: "none" }}
          // bg={isActive ? "cyan.100" : "red.500"}
          // {...(isActive&&activeStyle)}
        >
          <Flex
            align="center"
            p="4"
            mx="4"
            borderRadius="lg"
            role="group"
            cursor="pointer"
            _hover={{
              bg: "red.400",
              color: "white",
            }}
            {...rest}
            {...(isActive && activeStyle)}
          >
            {icon && (
              <Icon
                mr="4"
                fontSize="16"
                _groupHover={{
                  color: "white",
                }}
                as={icon}
              />
            )}
            {children}
          </Flex>
        </Box>
      )}
    </NavLink>
  );
};

const PasswordModal = ({ isPassOpen, onPassClose }) => {
  const { currentTradeUser,updateCurrentTradeUser } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [cfmNewPassword, setCfmNewPassword] = useState("");
  const [errors, setErrors] = useState({
    oldPassword: false,
    newPassword: false,
    cfmNewPassword: false,
    MismatchPassword: false,
  });

  useEffect(() => {
    if (!currentTradeUser) {
      navigate("/trade_login");
    }
  }, [currentTradeUser, navigate]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const check = {
      ...errors,
      oldPassword: !oldPassword || oldPassword === "",
      newPassword: !newPassword || newPassword === "",
      cfmNewPassword: !cfmNewPassword || cfmNewPassword === "",
      MismatchPassword: newPassword !== cfmNewPassword,
    };
    setErrors(check);
    console.debug(
      "new:",
      newPassword,
      "cfm: ",
      cfmNewPassword,
      "equals: ",
      newPassword !== cfmNewPassword
    );
    const anyError = Object.values(check).reduce((r, c) => r || c, false);
    if (anyError) {
      console.debug("anyErrors: ", anyError);
      return;
    }

    const formData = new FormData();
    if (currentTradeUser) {
      formData.append("id", currentTradeUser.ID);
    } else {
      navigate("/trade_login");
      return;
    }
    formData.append("old_password", oldPassword);
    formData.append("new_password", newPassword);
    formData.append("cfm_new_password", cfmNewPassword);
    const rsp = await changePasswordForTradeUser(
      currentTradeUser.ID,
      formData,
      currentTradeUser
    );
    if (!rsp.success) {
      showToast(rsp.message, "Failed", "error");
      if (rsp.status === 401) {
        navigate("/trade_login");
      }
    } else {
      showToast(rsp.message, "Password Changed, Re-login Please.", "success");
      onPassClose();
      updateCurrentTradeUser(null);
      navigate("/trade_login");
    }
  };
  return (
    <Modal isOpen={isPassOpen} onClose={onPassClose}>
      <ModalOverlay />
      <ModalContent w={{ base: "80%", sm: "full" }}>
        <form onSubmit={handleSubmit}>
          <ModalHeader>Change Password</ModalHeader>
          <ModalCloseButton colorScheme="red" />
          <ModalBody>
            <Box>
              <FormControl isInvalid={errors.oldPassword} pb={4}>
                <FormLabel>Old Password</FormLabel>
                <Input
                  type="password"
                  value={oldPassword}
                  onChange={(event) => setOldPassword(event.target.value)}
                />
                {errors.oldPassword && (
                  <FormErrorMessage>Old password is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={errors.newPassword} pb={2}>
                <FormLabel>New Password</FormLabel>
                <Input
                  type="password"
                  value={newPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                />
                {errors.newPassword && (
                  <FormErrorMessage>New password is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                isInvalid={errors.cfmNewPassword || errors.MismatchPassword}
                pb={0}
              >
                <FormLabel>Confirm New Password</FormLabel>
                <Input
                  type="password"
                  value={cfmNewPassword}
                  onChange={(event) => setCfmNewPassword(event.target.value)}
                />
                {errors.cfmNewPassword ? (
                  <FormErrorMessage>
                    Repeat password is required.
                  </FormErrorMessage>
                ) : errors.MismatchPassword ? (
                  <FormErrorMessage>
                    New Password and Confirm New Password must match.
                  </FormErrorMessage>
                ) : (
                  <></>
                )}
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onPassClose}>
              Close
            </Button>
            <Button variant="ghost" colorScheme="red" type="submit">
              Submit
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
const MobileNav = ({ onNavOpen, ...rest }) => {
  const { currentTradeUser, updateCurrentTradeUser } = useContext(AuthContext);
  const passDisclosure = useDisclosure();
  const navigate = useNavigate();
  const signOut = () => {
    updateCurrentTradeUser(null);
    navigate("/");
  };
  const changePassword = () => {
    passDisclosure.onOpen();
  };
  return (
    <>
      <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue("white", "gray.900")}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        {...rest}
      >
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onNavOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />

        <Text
          display={{ base: "flex", md: "none" }}
          fontSize="2xl"
          fontFamily="monospace"
          fontWeight="bold"
        >
          Evyty
        </Text>

        <HStack w={"full"} spacing={{ base: "0", md: "6" }}>
          {/* <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink href="#">Home</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="#">Docs</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#">Breadcrumb</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb> */}
          <Spacer />
          <IconButton
            size="lg"
            variant="ghost"
            aria-label="open menu"
            icon={<FiBell />}
          />
          <Flex alignItems={"center"}>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: "none" }}
              >
                <HStack>
                  <Avatar
                    size={"sm"}
                    src={
                      "https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                    }
                  />
                  <VStack
                    display={{ base: "none", md: "flex" }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  >
                    <Text fontSize="sm">Trade Customer</Text>
                    <Text fontSize="xs" color="gray.600">
                      {/*{currentUser.USERNAME}*/}
                      {currentTradeUser ? atob(currentTradeUser.USERNAME) : ""}
                    </Text>
                  </VStack>
                  <Box display={{ base: "none", md: "flex" }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                bg={useColorModeValue("white", "gray.900")}
                borderColor={useColorModeValue("gray.200", "gray.700")}
              >
                {/*<MenuItem>Profile</MenuItem>*/}
                {/*<MenuItem>Settings</MenuItem>*/}
                <MenuItem onClick={changePassword}>Change Password</MenuItem>
                <MenuDivider />
                <MenuItem onClick={signOut}>Sign out</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
      <PasswordModal
        isPassOpen={passDisclosure.isOpen}
        onPassClose={passDisclosure.onClose}
      />
    </>
  );
};

export const TradePage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onNavOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Outlet />
      </Box>
    </Box>
  );
};
