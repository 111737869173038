// import { getBackendUrl } from "./server";
// import { useContext } from "react";
// import { AuthContext } from "../context/AuthContext";

// const old_fetch = fetch;
async function fetchWrapper(input, init, currentUser) {
  if (!currentUser || !currentUser.TOKEN) {
    return new Response(
      JSON.stringify({ success: false, message: "Unauthorized" })
    );
  }

  let headers = init.headers;
  if (!headers) {
    headers = {};
  }
  headers["authorization"] = `Bearer ${currentUser.TOKEN}`;
  init.headers = headers;

  // console.debug("init const old_fetch :", init);

  return await fetch(input, init);
}

export async function createColor(colorData, currentUser) {
  console.debug("function createColor(colorData):", colorData);
  const response = await fetchWrapper(
    `/api/v1/admin/color`,
    {
      method: "POST",
      headers: {
        "X-HEADER": "evyty-admin",
      },
      body: colorData,
    },
    currentUser
  );
  console.debug("createColor: ", response);
  return response.json();
}

export async function deleteColor(colorData, currentUser) {
  const response = await fetchWrapper(
    `/api/v1/admin/color/${colorData.ID}`,
    {
      method: "DELETE",
      headers: {},
    },
    currentUser
  );
  console.debug("deleteColor: ", response);
  return response.json();
}

export async function updateColor(cid, formData, currentUser) {
  const response = await fetchWrapper(
    `/api/v1/admin/color/${cid}`,
    {
      method: "PUT",
      headers: {},
      body: formData,
    },
    currentUser
  );
  console.debug("updateColor:", response);
  return response.json();
}

export async function createCategory(formData, currentUser) {
  console.debug("function createCategory(formData):", formData);
  const response = await fetchWrapper(
    `/api/v1/admin/category`,
    {
      method: "POST",
      headers: {},
      body: formData,
    },
    currentUser
  );
  console.debug("createCategory: ", response);
  return response.json();
}

export async function deleteCategory(catData, currentUser) {
  const response = await fetchWrapper(
    `/api/v1/admin/category/${catData.ID}`,
    {
      method: "DELETE",
      headers: {},
    },
    currentUser
  );
  console.debug("deleteCategory: ", response);
  return response.json();
}

export async function updateCategory(cid, formData, currentUser) {
  const response = await fetchWrapper(
    `/api/v1/admin/category/${cid}`,
    {
      method: "PUT",
      headers: {},
      body: formData,
    },
    currentUser
  );
  console.debug("updateCategory:", response);
  return response.json();
}

export async function createSubcategory(formData, currentUser) {
  console.debug("function createSubcategory(formData):", formData);
  const response = await fetchWrapper(
    `/api/v1/admin/subcategory`,
    {
      method: "POST",
      headers: {},
      body: formData,
    },
    currentUser
  );
  console.debug("createSubcategory: ", response);
  return response.json();
}

export async function deleteSubcategory(catData, currentUser) {
  const response = await fetchWrapper(
    `/api/v1/admin/subcategory/${catData.SUBCAT_ID}`,
    {
      method: "DELETE",
      headers: {},
    },
    currentUser
  );
  console.debug("deleteSubcategory: ", response);
  return response.json();
}

export async function updateSubcategory(scid, formData, currentUser) {
  const response = await fetchWrapper(
    `/api/v1/admin/subcategory/${scid}`,
    {
      method: "PUT",
      headers: {},
      body: formData,
    },
    currentUser
  );
  console.debug("updateSubcategory:", response);
  return response.json();
}

export async function searchProducts(q, limit, offset, currentUser) {
  const response = await fetchWrapper(
    `/api/v1/admin/product/search`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        q: q,
        limit: limit,
        offset: offset,
      }),
    },
    currentUser
  );
  // console.debug("searchProducts: ", response);
  return response.json();
}

export async function createProduct(formData, currentUser) {
  console.debug("function createProduct(formData):", formData);
  const response = await fetchWrapper(
    `/api/v1/admin/product`,
    {
      method: "POST",
      headers: {},
      body: formData,
    },
    currentUser
  );
  console.debug("createProduct: ", response);
  return response.json();
}

export async function updateProduct(prod_id, formData, currentUser) {
  const response = await fetchWrapper(
    `/api/v1/admin/product/${prod_id}`,
    {
      method: "PUT",
      headers: {},
      body: formData,
    },
    currentUser
  );
  console.debug("updateSubcategory:", response);
  return response.json();
}

export async function deleteProduct(prodData, currentUser) {
  const response = await fetchWrapper(
    `/api/v1/admin/product/${prodData.ID}`,
    {
      method: "DELETE",
      headers: {},
    },
    currentUser
  );
  console.debug("deleteSubcategory: ", response);
  if (response.status === 401) {
    //unauthorized
  }

  return response.json();
}

export async function userLogin(formData) {
  const rsp = await fetch("/api/v1/login", {
    method: "POST",
    body: formData,
  });
  // console.debug("userLogin - client: ", rsp);
  return rsp.json();
}

export async function getAllUsers(currentUser) {
  const rsp = await fetchWrapper(
    "/api/v1/admin/user",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
    currentUser
  );
  return rsp.json();
}

export async function getAllTradeUsers(currentUser) {
  const rsp = await fetchWrapper(
    "/api/v1/admin/trade_user",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
    currentUser
  );
  return rsp.json();
}

export async function deleteUser(userId, currentUser) {
  const rsp = await fetchWrapper(
    `/api/v1/admin/user/${userId}`,
    {
      method: "DELETE",
      headers: {},
    },
    currentUser
  );

  return rsp.json();
}

export async function deleteTradeUser(userId, currentUser) {
  const rsp = await fetchWrapper(
    `/api/v1/admin/trade_user/${userId}`,
    {
      method: "DELETE",
      headers: {},
    },
    currentUser
  );

  return rsp.json();
}
export async function createUser(formData, currentUser) {
  const response = await fetchWrapper(
    `/api/v1/admin/user`,
    {
      method: "POST",
      headers: {},
      body: formData,
    },
    currentUser
  );
  return response.json();
}
export async function createTradeUser(formData, currentUser) {
  const response = await fetchWrapper(
    `/api/v1/admin/trade_user`,
    {
      method: "POST",
      headers: {},
      body: formData,
    },
    currentUser
  );
  return response.json();
}
export async function updateUser(uid, formData, currentUser) {
  const response = await fetchWrapper(
    `/api/v1/admin/user/${uid}`,
    {
      method: "PUT",
      headers: {},
      body: formData,
    },
    currentUser
  );
  return response.json();
}
export async function updateTradeUser(uid, formData, currentUser) {
  const response = await fetchWrapper(
    `/api/v1/admin/trade_user/${uid}`,
    {
      method: "PUT",
      headers: {},
      body: formData,
    },
    currentUser
  );
  return response.json();
}
export async function changePasswordForTradeUser(uid, formData, currentUser) {
  const response = await fetchWrapper(
    `/api/v1/admin/trade_user/${uid}`,
    {
      method: "POST",
      headers: {},
      body: formData,
    },
    currentUser
  );
  return response.json();
}

export async function uploadPriceFile(formData, currentUser) {
  const response = await fetchWrapper(
    "/api/v1/admin/prices",
    {
      method: "POST",
      headers: {},
      body: formData,
    },
    currentUser
  );
  return response.json();
}

export async function deletePriceFile(body, currentUser) {
  const response = await fetchWrapper(
    "/api/v1/admin/prices",
    {
      method: "DELETE",
      headers: {},
      body: JSON.stringify(body),
    },
    currentUser
  );
  return response.json();
}

export async function checkToken(currentUser) {
  const response = await fetchWrapper(
    "/api/v1/admin/check_token",
    {
      method: "POST",
      headers: {},
    },
    currentUser
  );
  return response.json();
}
